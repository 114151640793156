import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { AppService } from "./app.service";
import { CookieService } from "ngx-cookie-service";
import { LoginModel, RegisterModel, TokenModel } from "../models";
import { environment } from '../../environments/environment';

@Injectable()
export class LoginService {

    get isLogado() {
        return this._cookieService.check(environment.COOKIE_AUTH);
    }
    get usuario(): TokenModel {
        return this.isLogado
            ? <TokenModel>(
                JSON.parse(atob(this._cookieService.get(environment.COOKIE_AUTH)))) : null;
    }

    get authorizationHeader(): string {
        return `Bearer ${this.usuario?.accessToken}`;
    }

    constructor(
        private http: HttpClient,
        private router: Router,
        private _cookieService: CookieService,
        private _appService: AppService
    ) {
    }

    generateToken(login: LoginModel): Observable<TokenModel> {
        return this.http.post<TokenModel>(
            `${environment.apiURLAuth}/login/generatetoken`, login
        );

    }

    addSession(user: TokenModel) {
        this.removeSession();
        this._cookieService.set(
            environment.COOKIE_AUTH,
            btoa(JSON.stringify(user)),
            new Date(user.expiration), '/'
        );
    }

    removeSession() {
        this._cookieService.delete(environment.COOKIE_AUTH, '/');
        this._appService.clearContext();
    }

    // externalGenerateToken(externalLogin: ExternalLoginModel): Observable<TokenModel> {
    // 	return this.http.post<TokenModel>(
    // 		`${environment.apiURL}login/externalgeneratetoken`,
    // 		externalLogin
    // 	);
    // }

    login(returnUrl: string = null): Observable<string> {
        return this.http.post<string>(
            `${environment.apiURL}login/login`,
            returnUrl
        );
    }

    register(model: RegisterModel): Observable<RegisterModel> {
        return this.http.post<RegisterModel>(`${environment.apiURL}login/Register`, model);
    }

    // alterarSenha(senha: AlterarSenhaModel) {
    // 	return this.http.post<AlterarSenhaModel>(
    // 		`${environment.apiURL}login/alterarSenha`,
    // 		senha
    // 	);
    // }

    // register(register: RegisterModel): Observable<RegisterModel> {
    // 	return this.http.post<RegisterModel>(
    // 		`${environment.apiURL}login/register`,
    // 		register
    // 	);
    // }

    // alterandoEmpresa(refreshToken: LoginRefreshTokenModel) {
    // 	return this.http.post<LoginRefreshTokenModel>(
    // 		`${environment.apiURL}login/alterandoempresa`, refreshToken
    // 	);
    // }


    // refreshToken(code: string, codigoUsuario: number, codigoEmpresa: number): Promise<TokenModel> {

    // 	var model = new LoginRefreshTokenModel(code, codigoUsuario, codigoEmpresa);


    // 	return new Promise((resolve, error) => {
    // 		this.http.post<TokenModel>(`${environment.apiURL}login/RefreshToken`, model)
    // 			.toPromise()
    // 			.then(ret => {
    // 				this.AdicionarSessao(ret);
    // 				resolve(ret);
    // 			})
    // 			.catch(er => error(er));
    // 	});

    // }

    // resetarSenha(resetar: ResetPasswordModel): Observable<ResetPasswordModel> {
    // 	return this.http.post<ResetPasswordModel>(
    // 		`${environment.apiURL}login/resetarSenha`, resetar);
    // }

    // recuperarSenha(reset: ForgotPasswordModel): Observable<string> {
    // 	return this.http.post<string>(`${environment.apiURL}login/recuperarSenha`, reset)
    // }

    logout() {
        this.router.navigateByUrl("/");
        localStorage.removeItem("user");
    }
}
