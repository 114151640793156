import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgPipesModule } from 'ngx-pipes';
import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumb.component';
import { InputComponent } from '../components/input/input.component';
import { ThousandPipe } from './pipes/thousand.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NotificationService } from '../services';
import { DialogService } from 'primeng/dynamicdialog';
import { SpinnerComponent } from 'app/components/spinner/spinner.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
    declarations: [
        BreadcrumbComponent,
        InputComponent,
        SpinnerComponent,
        ThousandPipe,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ProgressSpinnerModule,
        NgbModule,
        ReactiveFormsModule,
        NgSelectModule,
        TooltipModule,
        DropdownModule,
        TableModule,
        RippleModule,
        SweetAlert2Module.forRoot(),
        NgPipesModule,

    ],
    exports: [
        BreadcrumbComponent,
        SpinnerComponent,
        InputComponent,
        ThousandPipe,

    ],
    providers: [
        NotificationService,
        DialogService
    ]
})
export class SharedModule { }
