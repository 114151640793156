// import { Injectable } from '@angular/core';
// import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// import { LoginService } from 'app/services/login.service';
// import { ToastrService } from 'ngx-toastr';
// import { PermissionService } from 'app/services/permission.service';

// @Injectable()
// export class AuthGuard implements CanActivate {

//     constructor(private router: Router,
// 		private _toastr: ToastrService,
// 		private _loginService: LoginService, private _permissionService: PermissionService) { }


// 	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
// 		return new Promise((resolve) => {

// 			if (route.data.permission != null) {
// 				var dataPermission = route.data.permission;
// 				var permissionName = route.data.permission;
// 				// console.log(`${dataPermission} -> ${permissionName}`);
// 				this._permissionService.isValidAsync(dataPermission)
// 					.then(permission => {
// 						this.validateIslogged(resolve, permission, permissionName);
// 					});
// 			} else {
// 				this.validateIslogged(resolve, true, null);
// 			}
// 		});
// 	}

// 	private validateIslogged(resolve: (value?: boolean | PromiseLike<boolean>) => void, permission: boolean, permissionName: string) {
// 		if (this._loginService.isLogado) {
// 			// console.log('validateIslogged -> isLogado');
// 			if (permission == false) {
// 				this._toastr.error(`Suas permissões não lhe dao acesso ao recurso solicitado, necessário a permissão: ${permissionName}`, "Acesso não autorizado: ");
// 				// console.log('validateIslogged -> isLogado -> false');
// 				this.router.navigate(['/dashboard']);
// 				resolve(false);
// 			} else {
// 				// console.log('validateIslogged -> isLogado -> true');
// 				resolve(true);
// 			}
// 		}
// 		else {
// 			// console.log('validateIslogged -> NOT isLogado');
// 			this.router.navigate(['/login']);
// 			resolve(false);
// 		}
// 	}
// }

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '../services';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private _loginService: LoginService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        return new Promise((resolve) => {
            if (this._loginService.isLogado)
                resolve(true);
            else {
                this.router.navigate(['/auth/login']);
                resolve(false);
            }
        });
    }
}
