import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable()
export class LoadingService {

    @Output() loading: EventEmitter<ILoading>;

	constructor() {
		this.loading = new EventEmitter<ILoading>();
	}

	show(message: string = 'Aguarde...') {
		const objToEmit = {message, show: true}
		this.loading.emit(objToEmit);
	}

	hide() {
		this.loading.emit({ show: false, message: null });
	}

}

interface ILoading {
	message: string;
	show: boolean
}
