import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { LoginService } from "./login.service";
import { environment } from '../../environments/environment';
import { CurrencyModel } from "app/models";

@Injectable()
export class CurrencyService {

    constructor(
        private http: HttpClient,
        private _loginService: LoginService,
        private router: Router
    ) { }

    get(id: number): Observable<CurrencyModel> {
        return this.http.get<CurrencyModel>(
            `${environment.apiURL}/currency/${id}`);
    }

    getById(id: string): Observable<CurrencyModel> {
        return this.http.get<CurrencyModel>(
            `${environment.apiURL}/currency/${id}`);
    }

    getAll(): Observable<CurrencyModel[]> {
        return this.http.get<CurrencyModel[]>(
            `${environment.apiURL}/currency`);
    }

    save(currency: CurrencyModel): Observable<CurrencyModel> {
        if (currency.id == null)
            return this.http.post<CurrencyModel>(
                `${environment.apiURL}/currency`, currency);

        return this.http.put<CurrencyModel>(
            `${environment.apiURL}/currency`, currency);
    }

}
