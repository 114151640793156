export class LoginModel {
    email: string = null;
    user: string = null;
    password: string = null;
    rememberMe: boolean = false;

    constructor(email: string, password: string) {
        this.email = email;
        this.password = password;
    }
};
