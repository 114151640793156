import { Routes } from "@angular/router";
import { LoginPageComponent } from "./login-page/login-page.component";
import { RecoveryPageComponent } from "./recovery-page/recovery-page.component";
import { RegisterPageComponent } from "./register-page/register-page.component";

export const AuthRoutes: Routes = [
    {
        path:"",
        component: LoginPageComponent,
        canActivate: [],
        data: {}
    },

    {
        path:"register",
        component: RegisterPageComponent,
        canActivate: [],
        data: {}
    },

    {
        path:"recovery",
        component: RecoveryPageComponent,
        canActivate: [],
        data: {}
    },

]
