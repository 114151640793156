import { Component, OnInit, Input, ContentChild } from '@angular/core';
import { NgModel, FormControlName } from '@angular/forms'

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html'
})

export class InputComponent implements OnInit {

    @Input() label: string
    @Input() errorMessage: string
    @Input() showTip: boolean = true

    input: any
    numberPattern = /^[0-9]*$/

    @ContentChild(NgModel, {static:false}) model: NgModel
    @ContentChild(FormControlName, {static:false}) control: FormControlName

    constructor() { }

    ngOnInit() {
    }

    ngAfterContentInit() {
        this.input = this.model || this.control
        if (this.input === undefined) {
            throw new Error('Esse componente precisa ser usado com uma diretiva ngModel ou formControlName')
        }
    }

    hasSuccess(): boolean {
        return this.input.valid && (this.input.dirty || this.input.touched)
    }

    hasError(): boolean {
        return this.input.invalid && (this.input.dirty || this.input.touched)
    }

}
