
import { Injectable, Inject } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import Swal from 'sweetalert2';

@Injectable()
export class UtilService {

    constructor(private _http: HttpClient, private _toastr: ToastrService) {
    }

    ResponseErrorMessage(response: HttpErrorResponse) {
        if (response.error != null && typeof response.error === "object") {
			var i = (<any[]>response.error).length;
            if (i > 0) {
                var messages = '';
				(<any[]>response.error).forEach(x => messages += ' ' + x.value);

				// this._toastr.error(messages);
                // this._appConfirmService.confirm( { title: 'Confirmar', message: mensagens });
            }
            // else
                // this._snackBar.open((<any[]>response.error.erros)[0].mensagem, 'Fechar', { duration: 10000 });
        }
    }

    IsNullObject(obj: any) {
        return obj == null;
    }

    IsNullOrEmpty(string: string) {
        if (string == null)
            return true;

        if (string.trim() == '')
            return true;

        if (string == undefined)
            return true;

        return false;
    }

    // ObterEnderecoPorCEP(cep: string): Promise<EnderecoBuscaModel> {
    //     return new Promise((resolve) => {
    //         this._http.get<any>(`https://viacep.com.br/ws/${cep.replace('-','').replace('.', '')}/json`)
    //             .toPromise()
    //             .then(ret => {
    //                 var endereco = new EnderecoBuscaModel(ret.cep, ret.logradouro, ret.bairro, ret.localidade, ret.uf);
    //                 resolve(endereco);
    //             })
    //             .catch(ret => {
    //                 resolve(null);
    //             });
    //     });
    // }

    FormValidate(form: FormGroup) {
        Object.keys(form.controls).forEach((campo) => {
            const controle = form.get(campo);
            controle.markAsDirty();
            if (controle instanceof FormGroup) {
                this.FormValidate(controle);
            }
            else if (controle instanceof FormArray) {
                (controle as FormArray).controls.forEach(ctrl => this.FormValidate(<FormGroup>ctrl));
            }
        });
    }

    Filter<T>(data: T[], term: string, properties: string[]): T[] {
        term = term.toLowerCase();
        return data.filter(obj => {
            var valid = false;
            properties.forEach(prop => {
                if (!isNullOrUndefined(obj[prop]) && obj[prop].toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(term))
                    valid = true;
            });
            return valid;
        });
    }

}
