import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router';


@Component({
  selector: 'app-recovery-page',
  templateUrl: './recovery-page.component.html',
  styleUrls: ['./recovery-page.component.scss'],
})
export class RecoveryPageComponent implements OnInit {
;

  recoveryForm: FormGroup;

  constructor(private fb: FormBuilder,
              private _router: Router,
              ) { }

  ngOnInit() {
    this.recoveryForm = this.fb.group({
      email: [],
      question: [],
      answer: [],
    })
  }

  recover() {
    console.log(this.recoveryForm.value);
  }
}
