import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactPageComponent implements OnInit {

  // Map pointer coordinates
  lat = 40.730610;
  lng = -73.935242;

  contactForm: FormGroup

  constructor(private fb: FormBuilder,
    private _router: Router,
    // private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.contactForm = this.fb.group({
      name: [null],
      email: [null],
      country: [null],
      subject: [null],
      message: [null],
    })
  }

  enviar() {

  }
}
