export class PointerMapInnerModel implements PointerMap {
    radius: number = null;
    latitude: number = null;
    longitude: number = null;
    fillKey: string = null;


    constructor(radius: number, latitude: number, longitude: number, fillKey: string) {
        this.radius = radius;
        this.latitude = latitude;
        this.longitude = longitude;
        this.fillKey = fillKey;
    }

}

export class PointerMapOuterModel implements PointerMap {

    radius: number;
    latitude: number;
    longitude: number;
    popupOnHover: boolean = false;
    borderColor: string = null;
    borderOpacity: number = null;
    borderWidth: number = null;
    value: string = null;
    location: string = null;

    constructor(radius: number, latitude: number, longitude: number, popupOnHover: boolean, borderColor: string, borderOpacity: number, borderWidth: number, value: string, location: string) {
        this.radius = radius;
        this.latitude = latitude;
        this.longitude = longitude;
        this.popupOnHover = popupOnHover;
        this.borderColor = borderColor;
        this.borderOpacity = borderOpacity;
        this.borderWidth = borderWidth;
        this.value = value;
        this.location = location;
    }

}

export interface PointerMap {
    radius: number;
    latitude: number;
    longitude: number;
}
