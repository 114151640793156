import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { LoginService } from "./login.service";
import { environment } from '../../environments/environment';
import { SchoolModel } from "app/models/school.model";

@Injectable()
export class SchoolService {

    constructor(
        private http: HttpClient,
        private _loginService: LoginService,
        private router: Router
    ) { }

    get(id: number): Observable<SchoolModel> {
        return this.http.get<SchoolModel>(
            `${environment.apiURL}/schools/${id}`);
    }

    getById(id: string): Observable<SchoolModel> {
        return this.http.get<SchoolModel>(
            `${environment.apiURL}/schools/${id}`);
    }

    getAll(): Observable<SchoolModel[]> {
        return this.http.get<SchoolModel[]>(
            `${environment.apiURL}/schools`);
    }

    save(user: SchoolModel): Observable<SchoolModel> {
        if (user.id == null)
            return this.http.post<SchoolModel>(
                `${environment.apiURL}/schools`, user);

        return this.http.put<SchoolModel>(
            `${environment.apiURL}/schools`, user);
    }

}
