
import { takeUntil } from 'rxjs/operators';
import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    AfterViewInit
} from '@angular/core';

import { Subject } from 'rxjs';
import { smallPieData} from './dashboard-charts-data';
import * as Datamap from 'datamaps/dist/datamaps.world.min.js';
import { ResizeService } from 'app/resize/resize.service';
import { pointPlugin } from 'app/components/map/points-plugin';
import { CHART_COLOR_4, CHART_COLOR_5, CHART_COLOR_1, CHART_COLOR_2, CHART_COLOR_3, CHART_COLOR_6 } from 'app/utils/colors';
import { CityService } from 'app/services/city.service';
import { CityModel } from 'app/models/city.model';
import { PointerMapInnerModel, PointerMapOuterModel } from 'app/models/pointer-map.model';

@Component({
    selector: 'app-dashboard-page',
    templateUrl: './dashboard-page.component.html',
    styleUrls: ['./dashboard-page.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DashboardPageComponent implements OnInit, OnDestroy, AfterViewInit {
    mapData: any[] = [];
    cities: CityModel[] = [];

    // Chart elements references and echarts instances
    // @ViewChild('polarClockChart')
    // polarClockChartElementRef: ElementRef;
    // polarClockChart;
    // @ViewChild('groupBarChart')
    // groupBarChartElementRef: ElementRef;
    // groupBarChart;
    // @ViewChild('trendLineChart')
    // trendLineChartElementRef: ElementRef;
    // trendLineChart;
    // @ViewChild('radarChart')
    // radarChartElementRef: ElementRef;
    // radarChart;
    // @ViewChild('areaChart')
    // areaChartElementRef: ElementRef;
    // areaChart;
    // @ViewChild('lineChart')
    // lineChartElementRef: ElementRef;
    // lineChart;
    // @ViewChild('barChart')
    // barChartElementRef: ElementRef;
    // barChart;
    // @ViewChild('pieChart')
    // pieChartElementRef: ElementRef;
    // pieChart;
    // Data map element reference and instance
    @ViewChild('worldMap')
    worldMapElementRef: ElementRef;
    worldMap;

    // Models for tables and list items
    mapBubbles;
    polarClockItems = [
        {
            value: 0.5,
            color: '#40515d',
            name: 'USA',
            delta: 0.07
        },
        {
            value: 0.6,
            color: CHART_COLOR_4,
            name: 'China',
            delta: 0.03
        },
        {
            value: 0.7,
            color: CHART_COLOR_5,
            name: 'UK',
            delta: -0.05
        },
        {
            value: 0.8,
            color: CHART_COLOR_1,
            name: 'France',
            delta: 0.02
        }
    ];
    barGroupItems = {
        labels: [26, 27, 28, 29, 30, 31],
        items: [
            {
                name: 'Sales',
                color: CHART_COLOR_1,
                values: [110, 105, 140, 60, 180, 110]
            },
            {
                name: 'New customers',
                color: CHART_COLOR_2,
                values: [150, 190, 165, 110, 150, 150]
            },
            {
                name: 'Reviews',
                color: CHART_COLOR_3,
                values: [180, 145, 125, 210, 90, 180]
            },
            {
                name: 'Phone calls',
                color: CHART_COLOR_5,
                values: [140, 155, 80, 175, 125, 110]
            }
        ]
    };
    averageDynamicItems = [
        {
            company: 'Pixel Garden',
            progress: 90,
            location: 'Berlin, Germany',
            changes: 0.8,
            smallPie: smallPieData(0.8),
            budget: 587236
        },
        {
            company: 'NORD & Co',
            progress: 80,
            location: 'Warsaw, Poland',
            changes: 0.75,
            smallPie: smallPieData(0.75),
            budget: 198526
        },
        {
            company: 'Ledook',
            progress: 60,
            location: 'Washington, USA',
            changes: 0.45,
            smallPie: smallPieData(0.45),
            budget: 265478
        },
        {
            company: 'Luxware',
            progress: 50,
            location: 'Paris, France',
            changes: 0.55,
            smallPie: smallPieData(0.55),
            budget: 351268
        },
        {
            company: 'Fin Trend',
            progress: 40,
            location: 'Oslo, Norway',
            changes: 0.38,
            smallPie: smallPieData(0.38),
            budget: 845236
        }
    ];
    expenseItems = [
        {
            id: 142,
            tasks: 'Fix new bug',
            assigned: 'John Doe',
            time: '3h',
            status: 'in-progress',
            statusName: 'In progress'
        },
        {
            id: 198,
            tasks: 'Create db',
            assigned: 'John Doe',
            time: '12h',
            status: 'done',
            statusName: 'Done'
        },
        {
            id: 120,
            tasks: 'Add service',
            assigned: 'John Doe',
            time: '18h',
            status: 'missed',
            statusName: 'Missed'
        }
    ];
    // Informer to stop using observables after component is destroyed
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    // Create local variable with same name allows to use Math at html template definitions
    Math = Math;

    constructor(
        private resizeService: ResizeService,
        private _citiesService: CityService,
        // private MapService: MapService
    ) { }

    allCities$ = this._citiesService.cities$.pipe();

    // citiesSetup$: Observable<Datamap> = this.allCities$.pipe(map(x => this.createPointerMap(x)));
    ngOnInit() {
        // this.getMapData();
    }


    ngAfterViewInit(): void {
        // setTimeout(() => {
        //     this.polarClockChart = echarts.init(
        //         this.polarClockChartElementRef.nativeElement
        //     );
        //     this.polarClockChart.setOption(getPolarChartData(this.polarClockItems));

        //     this.groupBarChart = echarts.init(
        //         this.groupBarChartElementRef.nativeElement
        //     );
        //     this.groupBarChart.setOption(getGroupBarData(this.barGroupItems));

        //     this.trendLineChart = echarts.init(
        //         this.trendLineChartElementRef.nativeElement
        //     );
        //     this.trendLineChart.setOption(trendLineChartData);

        //     this.radarChart = echarts.init(this.radarChartElementRef.nativeElement);
        //     this.radarChart.setOption(radarChartData);

        //     this.areaChart = echarts.init(this.areaChartElementRef.nativeElement);
        //     this.areaChart.setOption(areaChartData);

        //     this.lineChart = echarts.init(this.lineChartElementRef.nativeElement);
        //     this.lineChart.setOption(lineChartData);

        //     this.barChart = echarts.init(this.barChartElementRef.nativeElement);
        //     this.barChart.setOption(barChartData);

        //     this.pieChart = echarts.init(this.pieChartElementRef.nativeElement);
        //     this.pieChart.setOption(pieChartData);

        // });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    /**
     * Method for generating circles on world map
     */

    createMap(data) : Datamap{
        this.mapBubbles = data;

        this.worldMap = new Datamap({
            element: this.worldMapElementRef.nativeElement,
            responsive: true,
            aspectRatio: 0.47,
            fills: {
                defaultFill: 'transparent',
                'color-type-1': CHART_COLOR_4,
                'color-type-2': CHART_COLOR_1,
                'color-type-3': '#ff7f0e ',
                'color-type-4': '#ff7f0e ',
                'color-type-5': '#ff7f0e ',
                'color-type-6': CHART_COLOR_6,
                'color-type-7': '#ff7f0e ',
                'color-type-8': '#ff7f0e '
            },
            geographyConfig: {
                popupOnHover: false,
                highlightOnHover: false
            }
        });

        this.worldMap.addPlugin('points', pointPlugin);
        this.worldMap.points({});
        this.worldMap.bubbles(this.mapBubbles, {
            popupTemplate: function (geography, data) {
                // This function should just return a string
                return `
            <div class='tooltip bs-tooltip-top show row' role='tooltip'>
              <div class='tooltip-inner text-nowrap text-left  '>
                <div class='pl-1'>${data.value}</div>
                <div><i class='material-icons pr-1 align-bottom '><span class='h6'>location_on</span></i>${data.location
                    }</div>
              </div>
            </div>`;
            },
            fillOpacity: 1,
            highlightOnHover: false,
            borderWidth: 0,
            borderOpacity: 0,
            animate: false
        });

        this.resizeService.resizeInformer$.pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                // this.polarClockChart.resize();
                // this.groupBarChart.resize();
                // this.trendLineChart.resize();
                // this.radarChart.resize();
                // this.areaChart.resize();
                // this.lineChart.resize();
                // this.barChart.resize();
                // this.pieChart.resize();
                this.worldMap.resize();
            });

        return this.worldMap;
    }

    private getBubbleRadiusOuter() {
        return window.innerWidth < 700 ? 10 : 20;
    }

    private getBubbleRadiusInner() {
        return window.innerWidth < 700 ? 5 : 10;
    }


    // getMapData() {
    //     this._citiesService
    //         .getAll()
    //         .toPromise()
    //         .then((cities: CityModel[]) => {
    //             this.cities = cities;
    //             this.createPointerMap(this.cities);
    //         }).catch(error => {
    //         });

    // }

    createPointerMap(cities: CityModel[]) : Datamap {


        let dataMap = [];

        let pointsInner = this.createPointerInnerMap(cities);
        let pointsOuter = this.createPointerOuterMap(cities);

        dataMap = [...pointsInner, ...pointsOuter];

        this.mapData = dataMap;
        return this.createMap(this.mapData);
    }

    createPointerInnerMap(cities: CityModel[]): any[] {
        let dataMap = [];
        cities.forEach(city => {
            var citiesInnerMap = new PointerMapInnerModel(this.getBubbleRadiusInner(), city.latitude, city.longitude, 'color-type-2');
            dataMap.push(citiesInnerMap);
        });
        return dataMap;
    }

    createPointerOuterMap(cities: CityModel[]): any[] {
        let dataMap = [];
        cities.forEach(city => {
            var citiesOuterMap = new PointerMapOuterModel(this.getBubbleRadiusOuter(), city.latitude, city.longitude, false, CHART_COLOR_1, 1, 2, "$184 217", city.name);
            dataMap.push(citiesOuterMap);
            dataMap[0].chart
        });
        return dataMap;
    }
}
