import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CityModel } from "app/models/city.model";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError, map, shareReplay, tap } from "rxjs/operators";

@Injectable()
export class CityService {
    constructor(
        private http: HttpClient
    ) { }

    cities$ = this.http.get<CityModel[]>(`${environment.apiURL}/city`)
        .pipe(
            tap(response => console.log(JSON.stringify(response))),
            map(cities => cities),
            shareReplay(1),
            // catchError(this.handleError)
        );
    // }


    save(city: CityModel): Observable<CityModel> {
        if (city.id == null)
            return this.http.post<CityModel>(
                `${environment.apiURL}/city`, city);

        return this.http.put<CityModel>(
            `${environment.apiURL}/city`, city);
    }
}
