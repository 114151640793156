import { Injectable, EventEmitter } from "@angular/core";
import { CompanyModel } from "app/models/company.model";


@Injectable()
export class AppService {

	changeCompanyContext: EventEmitter<CompanyModel>;
	company: CompanyModel = null;

	constructor() {
		this.changeCompanyContext = new EventEmitter<CompanyModel>();
		this.changeCompanyContext.subscribe(company => this.company = company);
	}

	clearContext() {
		this.company = null;
		this.changeCompanyContext.emit(null);
	}

}
