import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { LoadingService } from './services/loading.service';

// Google analytics global variable
declare let ga: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    // When this field is set true, it removes class from root component. That causes pre loader to be removed.
    @HostBinding('class.loading') loading;
    constructor(public router: Router, private _loadingService: LoadingService, private _primeNgConfig: PrimeNGConfig) {

        this._primeNgConfig.ripple = true;

        this._loadingService
            .loading
            .subscribe(loading => {
                console.log(loading)
                this.loading = loading.show
            });
        // Send goggle analytics info about page change.
        // If you do not need google analytics in your project, you can delete this part
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                ga('set', 'page', event.urlAfterRedirects);
                ga('send', 'pageview');

            }
        });
    }
}
