import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from 'app/services';
import { LoadingService } from 'app/services/loading.service';
import Swal from 'sweetalert2';
import { LoginModel } from '../../../models/login.model';
import { LoginService } from '../../../services/login.service';
import { UtilService } from '../../../services/util.service';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginPageComponent implements OnInit {
    loginForm: FormGroup;

    constructor(private _fb: FormBuilder, private _utilService: UtilService, private _notificationService: NotificationService, private _loginService: LoginService, private _router: Router, private _loadingService: LoadingService) {

        this.loginForm = this._fb.group({
            email: [null, Validators.required],
            password: [null, Validators.required],
        });
    }

    ngOnInit() { }

    tryLogin() {
        this._loadingService.show();

        if (!this.loginForm.valid) {
            this._utilService.FormValidate(this.loginForm);
            this._loadingService.hide();
            return;
        }

        var formValues = this.loginForm.value;

        var login = new LoginModel(formValues.email, formValues.password);

        this._loginService
            .generateToken(login)
            .toPromise()
            .then(async token => {
                this._loadingService.hide();
                this._loginService.addSession(token);
                this._router.navigate(['/dashboard']);
            })
            .catch(_ => {
                console.log(_.error.erros);
                this._utilService.ResponseErrorMessage(_.error.erros);
                this._loadingService.hide();

                this._notificationService.notificationFailedLogin(_.error.erros[0].mensagem, '/assets/images/login/errorLogin.svg')
            });
    }

}
