import { AfterViewInit, Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { ResizeService } from '../../resize/resize.service';
import { routerTransition } from '../../utils/page.animation';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService, UserService } from 'app/services';
import { TokenModel } from 'app/models';

/**
 * This page wraps all other pages in application, it contains header, side menu and router outlet for child pages
 */
@Component({
    selector: 'app-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss'],
    animations: [routerTransition],
    encapsulation: ViewEncapsulation.None
})
export class MainPageComponent implements OnInit, AfterViewInit {
    // Model for side menu
    outlet: any;
    photoUrl:string = null;
    menuModel = [
        {
            title: 'Dashboard',
            routerUrl: '/dashboard',
            // iconClass: 'material-icons',
            // iconCode: 'dashboard',
        },

        {
            title: 'Paises',
            routerUrl: '/countries',
            // iconClass: 'material-icons',
            // iconCode: 'explore',
        },

        // {
        //   title: 'User pages',
        // //   iconClass: 'material-icons',
        // //   iconCode: 'person',
        //   children: [
        //     {
        //       title: 'Login',
        //       routerUrl: '/login'
        //     },
        //     {
        //       title: 'Sing up',
        //       routerUrl: '/register'
        //     },
        //     {
        //       title: 'Profile',
        //       routerUrl: '/main/profile'
        //     },
        //     {
        //       title: 'Coming soon',
        //       routerUrl: '/coming-soon'
        //     },
        //     {
        //       title: 'Maintenance',
        //       routerUrl: '/maintenance'
        //     },
        //     {
        //       title: 'Not found',
        //       routerUrl: '/404'
        //     }
        //   ]
        // },
        {
            title: 'Cidades',
            // iconClass: 'material-icons',
            // iconCode: 'map',
            routerUrl: '/cities',
        },

        {
            title: 'Moedas',
            routerUrl: '/currencies',
            // iconClass: 'material-icons',
            // iconCode: 'attach_money',
        },

        {
            title: 'Escolas',
            routerUrl: '/schools',
            // iconClass: 'material-icons',
            // iconCode: 'school',
        },

        //   children: [
        //     {
        //       title: 'Products',
        //     },
        //     {
        //       title: 'Product page',
        //       routerUrl: '/main/product-details'
        //     },
        //     {
        //       title: 'Cart',
        //       routerUrl: '/main/cart'
        //     },
        //     {
        //       title: 'Pricing Tables',
        //       routerUrl: '/main/pricing-tables'
        //     },
        //     {
        //       title: 'Contacts',
        //       routerUrl: '/main/contact'
        //     }

        //   ]
        // },

        //   {
        //     title: 'Label',
        //     count: 10,
        // //     iconClass: 'material-icons',
        // //     iconCode: 'label',
        //     routerUrl: '',
        //     children: []
        //   },
        //   {
        //     title: 'Multilevel',
        //     iconClass: 'material-icons',
        //     iconCode: 'clear_all',
        //     children: [
        //       {
        //         title: 'Level 1',
        //         children: [
        //           {
        //             title: 'Level 2',
        //           },
        //           {
        //             title: 'Level 2',
        //           },
        //           {
        //             title: 'Level 2',
        //           },
        //         ]
        //       },
        //       {
        //         title: 'Level 1',
        //       },
        //       {
        //         title: 'Level 1',
        //       },
        //     ]
        //   },
        //   {
        //     title: 'Buy Vdi',
        //     iconClass: 'material-icons',
        //     iconCode: 'star',
        //     children: []
        //   }
    ];
    // Side menu options
    isSmallMenuMode = false;
    isMenuCollapsed = false;
    isMenuClosed = this.isSmallWidth();
    isOverlayMenuMode = this.isSmallWidth();
    // Side menu animation value. Is used for delay to render content after side panel changes
    sideNavTransitionDuration = 300;
    // Open/close options window
    isOptionsClosed = true;
    // Box layout option
    isBoxedLayout = false;
    // Fixed header option
    isFixedHeader = true;
    userInfo: TokenModel;

    constructor(
        private resizeService: ResizeService,
        translateService: TranslateService,
        private router: Router,
        private _userService: UserService,
        private loginService: LoginService) {
        this.onResize();
        // this language will be used as a fallback when a translation isn't found in the current language
        translateService.setDefaultLang('en');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translateService.use('en');

        this.userInfo = this.loginService?.usuario;
    }

    ngAfterViewInit(): void {
		this._userService
			.get(this.userInfo.idUser)
			.toPromise()
			.then(ret => {
                setTimeout(() =>   {
                    this.photoUrl = ret.photoUrl;
                },150 )
			});
	}


    /**
     * Window resize listener
     */
    @HostListener('window:resize', ['$event'])
    onResize() {
        this.resizeService.resizeInformer$.next();
        if (this.isSmallWidth()) {
            this.isOverlayMenuMode = true;
            this.isMenuClosed = true;
            setTimeout(() => this.resizeService.resizeInformer$.next(), this.sideNavTransitionDuration + 700);
        }
    }

    /**
     * Call resize service after side panel mode changes
     */
    onSideNavModeChange() {
        setTimeout(() => {
            this.resizeService.resizeInformer$.next();
        }, this.sideNavTransitionDuration)
    }

    ngOnInit(): void {
    }

    /**
     * Call resize service after box mode changes
     */
    toggleBoxed() {
        this.isBoxedLayout = !this.isBoxedLayout;
        setTimeout(() => {
            this.resizeService.resizeInformer$.next()
        }, 0);
    }

    toggleCompactMenu() {
        this.isSmallMenuMode = !this.isSmallMenuMode;
        setTimeout(() => {
            this.resizeService.resizeInformer$.next()
        }, this.sideNavTransitionDuration);
    }

    /**
     * Call resize service after side panel mode changes
     */
    toggleOverlayMode() {
        this.isOverlayMenuMode = !this.isOverlayMenuMode;
        setTimeout(() => {
            this.resizeService.resizeInformer$.next()
        }, this.sideNavTransitionDuration);
    }

    /**
     * Changes header mode
     */
    toggleFixedHeader() {
        this.isFixedHeader = !this.isFixedHeader;
    }

    /**
     * Return url as state, that will trigger animation when url changes
     * @param outlet
     * @returns {string}
     */
    getState(outlet) {
        return this.router.url;
    }

    private isSmallWidth() {
        return window.innerWidth < 700;
    }
}
