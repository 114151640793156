import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { UserPermissionModel } from "../models/user-permission.model";
import { LoginService } from "./login.service";
import { UserModel } from "../models";
import { environment } from '../../environments/environment';

@Injectable()
export class UserService {

    constructor(
        private http: HttpClient,
        private _loginService: LoginService,
        private router: Router
    ) { }

    get(id: string): Observable<UserModel> {
        return this.http.get<UserModel>(
            `${environment.apiURLAuth}/user/${id}`);
    }


    getAll(): Observable<UserModel[]> {
        return this.http.get<UserModel[]>(
            `${environment.apiURLAuth}/user`);
    }

    loadUserPermissions(): Observable<UserPermissionModel[]> {
        return this.http.get<UserPermissionModel[]>(
            `${environment.apiURLAuth}/user/profile/user-logged/permissions`);
    }

    save(user: UserModel): Observable<UserModel> {
        if (user.id == null)
            return this.http.post<UserModel>(`${environment.apiURLAuth}/user`, user);

        return this.http.put<UserModel>(`${environment.apiURLAuth}/user`, user);
    }

    saveImage(idUser: string, file: File) {
        var formData = new FormData();
        formData.append('file', file);

        return this.http.post(`${environment.apiURLAuth}/user/pictureUpload/${idUser}`, formData);
    }
}
