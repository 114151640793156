// Color constants for charts
export const CHART_TEXT_COLOR = '#a7a7a7';
export const CHART_COLOR_1 = '#0be3df';
export const CHART_COLOR_2 = '#3e4f5c';
export const CHART_COLOR_3 = '#0abfbc';
export const CHART_COLOR_4 = '#00c8fa';
export const CHART_COLOR_5 = '#5e788c';
export const CHART_COLOR_6 = '#5e788c';

export const CHART_COLOR_1_WITH_OPACITY = 'rgba(11, 227, 223, 0.5)';
export const CHART_COLOR_2_WITH_OPACITY = 'rgba(52, 79, 92, 0.5)';

