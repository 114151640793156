import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { UserPermissionModel } from "../models/user-permission.model";
import { LoginService } from "./login.service";
import { environment } from "environments/environment";
import { UserModel } from "app/models/user.model";

@Injectable()
export class DashboardService {

	constructor(
		private http: HttpClient,
		private _loginService: LoginService,
		private router: Router
	) { }

	get(id: number): Observable<UserModel> {
		return this.http.get<UserModel>(
			`${environment.apiURL}/user/${id}`);
	}

	getAll(): Observable<UserModel[]> {
		return this.http.get<UserModel[]>(
			`${environment.apiURL}/user`);
	}

	loadUserPermissions(): Observable<UserPermissionModel[]> {
		return this.http.get<UserPermissionModel[]>(
			`${environment.apiURL}/user/profile/user-logged/permissions`);
	}

	save(user: UserModel): Observable<UserModel> {
		if (user.id == null)
			return this.http.post<UserModel>(`${environment.apiURL}/user`, user);

		return this.http.put<UserModel>(`${environment.apiURL}/user`, user);
	}


	saveImage(idUser: string, file: File) {
        var formData = new FormData();
        formData.append('file', file);

        return this.http.post(`${environment.apiURL}/user/pictureUpload/${idUser}`, formData);
    }
}
