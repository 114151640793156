import {
	HttpEvent, HttpHandler, HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginService } from 'app/services';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	private static readonly applicationJson = 'application/json';

	constructor(
		private readonly _loginService: LoginService,
		private readonly _toastrService: ToastrService,
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let headers: any = {};

		// Setting the authorization header
		headers['authorization'] = this._loginService.authorizationHeader;

		if (!(request.body instanceof FormData)) {
			// Setting default http headers
			headers['content-type'] = TokenInterceptor.applicationJson;
			headers['accept'] = TokenInterceptor.applicationJson;
		}

		return next.handle(request.clone({ setHeaders: headers }));
	}
}
