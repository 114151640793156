import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { LoginService } from "./login.service";
import { UserPermissionModel } from "app/models/user-permission.model";
import { AppService } from "./app.service";
import { UserService } from "./user.service";

@Injectable()
export class PermissionService {
	private permissions: UserPermissionModel[] = [];
	permissionsChanged: EventEmitter<boolean>;
	isLoaded: boolean = false;
	private permissionChangedSubscription: Subscription;

	constructor(
		private http: HttpClient,
		private router: Router,
		private _userService: UserService,
		private _appService: AppService,
		private _toastr: ToastrService,
		private _loginService: LoginService
	) {
		this.permissionsChanged = new EventEmitter<boolean>();
		console.log('CRIADO');
		if (this._loginService.isLogado) {
			this.loadPermissions()
				.then(ret => {});
		}
		else {
			console.log('NAO FOI CRIADO');
		}
	}

	loadPermissions() {
		return new Promise((resolve, reject) => {
			this._userService
				.loadUserPermissions()
				.toPromise()
				.then(ret => {
					this.permissions = ret
					this.isLoaded = true;
					this.permissionsChanged.emit(true);
					resolve(ret);
					// console.log(this.permissions);
				})
				.catch(error => reject(error));
			});
	}

	isValid(permission: string) {
		var existPermission = this.permissions.find(x => x.constPermission == permission) == null ? false : true;
		console.log(permission + ' -> ' + existPermission);
		return existPermission;
	}

	isValidAsync(permission: string) {
		return new Promise<boolean>((resolve, reject) => {
			if (this.isLoaded) {
				resolve(this.isValid(permission));
			}
			else {
				this.permissionChangedSubscription = this.permissionsChanged.subscribe(ret => {
					resolve(this.isValid(permission));
					this.permissionChangedSubscription.unsubscribe();
				})
			}
		});
	}

}

