import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { EchartComponent } from './components/echart-component/echart.component';
import { MultimenuComponent } from './components/multimenu/multimenu.component';
import { AuthGuard } from './guards/auth.guard';
import { ComingSoonPageComponent } from './pages/auth/coming-soon-page/coming-soon-page.component';
import { MaintenancePageComponent } from './pages/auth/maintenance-page/maintenance-page.component';
import { NotFoundPageComponent } from './pages/auth/not-found-page/not-found-page.component';
import { ProfilePageComponent } from './pages/auth/profile-page/profile-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { CartPageComponent } from './pages/packagesOld/cart-page/cart-page.component';
import { PricingTablesPageComponent } from './pages/packagesOld/pricing-tables-page/pricing-tables-page.component';
import { ResizeService } from './resize/resize.service';
import { routing } from './routes/app-routes.module';
import { AlertService, CountryService, CurrencyService, UtilService } from './services';
import { NgbButtonsModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { BackgroundClassComponent } from './components/background-glass/background-class/background-class.component';
import { AuthModule } from './pages/auth/auth.module';
import { TokenInterceptor } from 'interceptors/token.interceptor';
import { TablePageComponent } from './components/table/table-page.component';
import { NgPipesModule } from 'ngx-pipes';
import { AlertComponent } from './directives/alert.component';
import { AppService } from './services/app.service';
import { LoadingService } from './services/loading.service';
import { LoginService } from './services/login.service';
import { PermissionService } from './services/permission.service';
import { UserService } from './services/user.service';
import { DynamicComponentDirective } from './shared/dynamicComponentModal/dynamic-component.directive';
import {SkeletonModule} from 'primeng/skeleton';
import { SafeurlPipe } from './shared/pipes/safe-url.pipe';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CityService } from './services/city.service';
import { SchoolService } from './services/school.service';
import { ErrorInterceptor } from 'interceptors/error.interceptor';
import { UrlInterceptor } from 'interceptors/url.interceptor';

const maskConfig: Partial<IConfig> = {
    validation: false,
  };


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

// AoT requires an exported function for factories for translate module
// export function createTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

@NgModule({
    declarations: [
        AppComponent,
        MultimenuComponent,
        MainPageComponent,
        CartPageComponent,
        PricingTablesPageComponent,
        EchartComponent,
        ContactPageComponent,
        TablePageComponent,
        AlertComponent,
        DashboardPageComponent,
        DynamicComponentDirective,
        ComingSoonPageComponent,
        MaintenancePageComponent,
        NotFoundPageComponent,
        ProfilePageComponent,
        BackgroundClassComponent,
        SafeurlPipe,

    ],
    imports: [
        routing,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AuthModule,
        NgPipesModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({}),
        PerfectScrollbarModule,
        ScrollingModule,
        NgbModule,
        NgbButtonsModule,
        SkeletonModule,
        NgxMaskModule.forRoot(maskConfig),
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-center',
            preventDuplicates: true,
            toastClass: 'ngx-toastr toast-colors-config toast-shadow-1'

        }), // ToastrModule added

    ],
    providers: [
        AuthGuard,
        PermissionService,
        UserService,
        UtilService,
        CookieService,
        CountryService,
        CurrencyService,
        SchoolService,
        ResizeService,
        AlertService,
        LoadingService,
        LoginService,
        CityService,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
        AppService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }],
    exports: [RouterModule,  SafeurlPipe, ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
