import { RouterModule, Routes } from '@angular/router';
import { MainPageComponent } from 'app/pages/main-page/main-page.component';
import { DashboardPageComponent } from 'app/pages/dashboard-page/dashboard-page.component';
import { LoginPageComponent } from 'app/pages/auth/login-page/login-page.component';
import { RegisterPageComponent } from 'app/pages/auth/register-page/register-page.component';
import { ComingSoonPageComponent } from 'app/pages/auth/coming-soon-page/coming-soon-page.component';
import { MaintenancePageComponent } from 'app/pages/auth/maintenance-page/maintenance-page.component';
import { NotFoundPageComponent } from 'app/pages/auth/not-found-page/not-found-page.component';
import { AuthGuard } from 'app/guards/auth.guard';
import { TablePageComponent } from 'app/components/table/table-page.component';
// Routes model for application. Some of the pages are loaded lazily to increase startup time.
const APP_ROUTES: Routes = [
    {
        path:'',
        component: MainPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                component: DashboardPageComponent
            },

            {
                path: 'cities',
                loadChildren: './pages/cities/cities.module#CitiesModule'
            },

            {
                path: 'countries',
                loadChildren: './pages/countries/countries.module#CountriesModule'
            },

            {
                path: 'currencies',
                loadChildren: './pages/currencies/currencies.module#CurrenciesModule'
            },

            {
                path: 'packages',
                loadChildren: './pages/packages/packages.module#PackagesModule'
            },

            {
                path: 'schools',
                loadChildren: './pages/schools/schools.module#SchoolsModule'
            },

            { path: 'login', component: LoginPageComponent },
            { path: 'table', component: TablePageComponent },

            // { path: 'city/details', component: CitiesDetailsPageComponent, canActivate: [AuthGuard] },
            // {path: 'slider', },
            { path: '', redirectTo: 'dashboard', pathMatch: 'prefix' },
            { path: '**', redirectTo: 'dashboard', pathMatch: 'prefix' }]
    },
    { path: 'login', component: LoginPageComponent },
    { path: 'register', component: RegisterPageComponent },
    { path: 'coming-soon', component: ComingSoonPageComponent },
    { path: 'maintenance', component: MaintenancePageComponent },
    { path: '404', component: NotFoundPageComponent },
    { path: '', redirectTo: '/dashboard', pathMatch: 'prefix' },
    { path: '**', redirectTo: '/dashboard', pathMatch: 'prefix' }

];

export const routing = RouterModule.forRoot(APP_ROUTES, { scrollOffset: [0, 0], scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' });
