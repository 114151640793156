import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { LoginService } from "./login.service";
import { environment } from '../../environments/environment';
import { CountryModel } from "app/models";

@Injectable()
export class CountryService {

    constructor(
        private http: HttpClient,
        private _loginService: LoginService,
        private router: Router
    ) { }

    get(id: number): Observable<CountryModel> {
        return this.http.get<CountryModel>(
            `${environment.apiURL}/country/${id}`);
    }

    getById(id: string): Observable<CountryModel> {
        return this.http.get<CountryModel>(
            `${environment.apiURL}/country/${id}`);
    }

    getAll(): Observable<CountryModel[]> {
        return this.http.get<CountryModel[]>(`${environment.apiURL}/country`);
    }

    save(user: CountryModel): Observable<CountryModel> {
        if (user.id == null)
            return this.http.post<CountryModel>(`${environment.apiURL}/country`, user);

        return this.http.put<CountryModel>(`${environment.apiURL}/country`, user);
    }

}
