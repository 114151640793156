import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { AuthRoutes } from './auth.routing';
import { LoginPageComponent } from './login-page/login-page.component';
import { RecoveryPageComponent } from './recovery-page/recovery-page.component';
import { RegisterPageComponent } from './register-page/register-page.component';

@NgModule({
    imports:
        [
            SharedModule,
            RouterModule.forChild(AuthRoutes),
            FormsModule,
            CommonModule,
            ReactiveFormsModule,
        ],

    exports:
        [
            LoginPageComponent,
            RegisterPageComponent,
            RecoveryPageComponent
        ],

    declarations:
        [
            LoginPageComponent,
            RegisterPageComponent,
            RecoveryPageComponent
        ],

    // providers: [CityService],
})
export class AuthModule { }
