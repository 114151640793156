import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { UtilService } from "app/services/util.service";
import { LoginService } from "app/services";
import { RegisterModel } from "app/models";

@Component({
    selector: "app-register-page",
    templateUrl: "./register-page.component.html",
    styleUrls: ["./register-page.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class RegisterPageComponent implements OnInit {
    ngOnInit(): void {
        throw new Error("Method not implemented.");
    }
    // label = "Cadastre-se";
    // registerForm: FormGroup;
    // clicked: boolean = false;

    // constructor(
    //     private _fb: FormBuilder,
    //     private _utilService: UtilService,
    //     // private _notificationService: NotificationService,
    //     // private resource: Resource,
    //     private _loginService: LoginService,
    //     private _router: Router
    // ) {}

    // ngOnInit() {
    //     this.registerForm = this._fb.group({
    //         id: null,
    //         email: [null, Validators.required],
    //         password: [null, Validators.required],
    //         phone: [null, Validators.required],
    //         confirmPassword: [null, Validators.required],
    //         terms: [false, Validators.required],
    //     });
    // }

    // salvar(register: RegisterModel) {
    //     this.clicked = true;
    //     if (!this.registerForm.valid) {
    //         this._utilService.FormValidate(this.registerForm);
    //         this.clicked = false;
    //         return;
    //     }

    //     //     if (register.senha != this.registerForm.controls.confirmarSenha.value) {
    //     //         // this._notificationService.notify(
    //     //         //     "As senhas não conferem, verifique as senhas digitadas",
    //     //         //     NotificationColorEnum.warning
    //     //         // );
    //     //     } else {
    //     //         this._loginService
    //     //             .(register)
    //     //             .toPromise()
    //     //             .then((resp) => {
    //     //                 this._router.navigate(["/login"]);
    //     //             });
    //     //     }
    // }
}
